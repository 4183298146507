import movie_thumb from "./movie_thumbnail.png"
import star from "./star.png"
import Logo from "./logo.svg"

export const imageList = {
    movie_tmb: movie_thumb,
    star:star,
    logo:Logo,
}

